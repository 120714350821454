import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { $REST_URI } from 'src/environments/environment';
import { DeviceInformation } from '../../model/daviceInfo.model';
import { Tag } from '../../model/organization.model';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  tags: Observable<Tag[]>;
  _tags: BehaviorSubject<Tag[]>;

  branchComplete: Observable<boolean>;
  _branchComplete: BehaviorSubject<boolean>;

  isSelectOrganisation: Observable<boolean>;
  _isSelectOrganisation: BehaviorSubject<boolean>;

  isSelectOrg: boolean = localStorage.getItem('_isSelectOrganisation') === "true" ? true : false;

  deviceInfo: Observable<DeviceInformation>;
  _deviceInfo: BehaviorSubject<DeviceInformation>;

  tempDeviceInfo: DeviceInformation = {
    browser: '',
    browser_version: '',
    device: '',
    deviceType: '',
    orientation: '',
    os: '',
    os_version: '',
    userAgent: ''
  }

  constructor(
    private http: HttpClient,
  ) {
    this._tags = new BehaviorSubject<Tag[]>([]);
    this.tags = this._tags.asObservable();
    this._branchComplete = new BehaviorSubject<boolean>(true);
    this.branchComplete = this._branchComplete.asObservable();
    this._isSelectOrganisation = new BehaviorSubject<boolean>(this.isSelectOrg);
    this.isSelectOrganisation = this._isSelectOrganisation.asObservable();
    this._deviceInfo = new BehaviorSubject<DeviceInformation>(this.tempDeviceInfo);
    this.deviceInfo = this._deviceInfo.asObservable();
   }

  getAllTag() {
    return this.http.get<Tag[]>(`${$REST_URI}/tag`);
  }

  // uploadLogoGetUrl(type: any) {
  //   let params = new HttpParams;

  //   params = params.append("ContentType", type);
  //   return this.http.get<any>(`${$REST_URI}/image/logo/upload`, { params: params });
  // }

  uploadImageFile(blob: any, url: any) {
    let headers = new HttpHeaders({});

    return this.http.put<any>(`${url}`, blob, {headers: headers});
  }

  // uploadAvatarGetUrl(type: any) {
  //   let params = new HttpParams;

  //   params = params.append("ContentType", type);
  //   return this.http.get<any>(`${$REST_URI}/file/image/avatar/upload`, { params: params });
  // }

  // downloadAvatarUrl(image: string) {
  //   console.log("image", image);
  //   let params = new HttpParams;

  //   params = params.append("avatar", image);
  //   return this.http.get<any>(`${$REST_URI}/file/image/avatar/download`, { params: params });
  // }


  organisationStatus(status: string) {
    let data = {status};
    return this.http.put<any>(`${$REST_URI}/organization/updateOrgStatus`, data);
  }

  getPrivateUrl(file: File, fileType: string, fileSubType: string) {
    let param = {mimeType: file.type, fileType: fileType, fileSubType: fileSubType}
    return this.http.get<any>(`${$REST_URI}/storage/private/signedURL`, {params: param});
  }
  
  getPrivateUrlWithNewToken(file: File, fileType: string, fileSubType: string, token: string) {
    let param = {mimeType: file.type, fileType: fileType, fileSubType: fileSubType};
    let headers = new HttpHeaders({'x-auth-token': token});
    return this.http.get<any>(`${$REST_URI}/storage/private/signedURL`, {headers: headers, params: param});
  }
 
  getPublicUrl(file: File, fileType: string, fileSubType: string) {
    let param = {mimeType: file.type, fileType: fileType, fileSubType: fileSubType}
    return this.http.get<any>(`${$REST_URI}/storage/public/signedURL`, {params: param});
  }

  getSearchTag() {
    return this.http.get<any>(`${$REST_URI}/search`);
  }
}
