<div class="loader-outer-container" [ngClass]="data.horizontalPosition">
    <div class="loader-container">
        <div class="icon-section">
            <mat-icon svgIcon="{{data.icon}}"></mat-icon>
        </div>
        <div class="content-section">
            <div class="heading">
                {{data.heading}}
            </div>
            <div class="text">
                {{data.message}}
            </div>
        </div>
        <div class="icon-section">
            <mat-icon svgIcon="noun_cross" class="close-icon" (click)="ref.dismiss()"></mat-icon>
        </div>
    </div>
</div>