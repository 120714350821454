import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, empty, Observable, of } from 'rxjs';
import { $REST_URI } from 'src/environments/environment';
import { ActivationUser, CurrentUser, UserDetail, UserDetailResponse } from '../../model/userDetail.model';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  currentUser: Observable<CurrentUser>;
  _currentUser: BehaviorSubject<CurrentUser>;

  userRoleClaims: Observable<any>;
  _userRoleClaims: BehaviorSubject<any>;

  private _empFormData = new BehaviorSubject(FormGroup);
  empFormData = this._empFormData.asObservable();

  // tempData: CurrentUser = new CurrentUser();

  tempData: CurrentUser = {
    _id: "",
    avatar: "",
    confirmPassword: "",
    contactCode: "",
    contactNumber: "",
    creationDate: "",
    email: "",
    lastModifiedDate: "",
    name: "",
    organizations: [],
    status: ""
  }

  constructor(
    private http: HttpClient,
    // private authService: AuthService
  ) {
    this.setUser(this.tempData);
    this._userRoleClaims = new BehaviorSubject<any>('');
    this.userRoleClaims = this._userRoleClaims.asObservable();
  }
  setUser(data: any) {
    this._currentUser = new BehaviorSubject<CurrentUser>(data);
    this.currentUser = this._currentUser.asObservable();
  }

  userActivation(data: ActivationUser, token: string) {
    let header = new HttpHeaders();
    header = header.append('x-auth-token', token);

    return this.http.put<UserDetailResponse>(`${$REST_URI}/user/activate`, data, { headers: header });
    // return of({
    //   accessToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MTljYmFkYTg5NmI2ZDc2ZjMxMWQ5M2YiLCJfdHlwZSI6IkxPR0lOIiwicm9sZSI6W10sIm9yZ2FuaXphdGlvbiI6WyI2MTljYmFkYTg5NmI2ZDc2ZjMxMWQ5M2IiXSwib3JnSWQiOm51bGwsImlhdCI6MTYzNzY2MTQ4MSwiZXhwIjoxNjM3NjYyMDgxfQ.WhNf8C3m6Rid9aR4d5bAXMa-_CvI1XTrh05br0hAQKY",
    //   refreshToken: "7e1dc5e95c6f622de4aab13381663c3a",
    //   user: {
    //     _id: "619cbada896b6d76f311d93f",
    //     email: "admin@launch.com",
    //     status: "ACTIVE",
    //     organizations: [
    //       "619cbada896b6d76f311d93b"
    //     ],
    //     creationDate: "2021-11-23T09:56:42.969Z",
    //     lastModifiedDate: "2021-11-23T09:57:32.925Z",
    //     __v: 0,
    //     contactCode: "+97",
    //     contactNumber: "9876543210",
    //     name: "Admin",
    //     avatar: "dsskjdfniuerbindkjnskdfb"
    //   }
    // });
  }

  forgotPassword(email: string) {
    return this.http.post(`${$REST_URI}/auth/forgotPassword`, email);
  }

  resetPassword(data: any, token: string) {
    let header = new HttpHeaders();
    header = header.append('x-auth-token', token);

    return this.http.put(`${$REST_URI}/auth/resetPassword`, data, { headers: header });
  }

  getUserAccessToken() {
    // let data = { refreshToken: this.authService.tokenGetter() };
    return this.http.get<any>(`${$REST_URI}/user/accessToken`);
  }

  getUserRolesClaims() {
    return this.http.get<any>(`${$REST_URI}/user/getClaims`);
  }

  updateempFormData(empForm: any) {
    console.log(empForm);
    this._empFormData.next(empForm);
  }

  deleteUser(id: string) {
    return this.http.delete<any>(`${$REST_URI}/user/${id}`);
  }
}
