import {
  HttpClient,
  HttpParams
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { $REST_URI } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FormsService {

  constructor(private _http: HttpClient) { }

  public createForm(data: any) {
    return this._http.post<any>(`${$REST_URI}/form`, data);
  }

  public getAllForms() {
    return this._http.get<any>(`${$REST_URI}/form`);
  }

  public deleteForm(formId: string) {
    return this._http.delete<any>(`${$REST_URI}/form/${formId}`);
  }

  public getFormByid(formId: string) {
    return this._http.get<any>(`${$REST_URI}/form/${formId}`);
  }

  public updateForm(id: string, data: any) {
    return this._http.put<any>(`${$REST_URI}/form/update/${id}`, data);
  }

  public getFormByOrgAndFormId(orgCode: string, formId: string) {
    return this._http.get<any>(`${$REST_URI}/sponsors/signup-form/${orgCode}/${formId}`);
  }

  public createFormSubmission(orgCode: string, formId: string, data: any) {
    return this._http.post<any>(`${$REST_URI}/sponsors/signup-form/${orgCode}/${formId}`, data);
  }

  public getFormSubmission(formId: string, query: any) {
    let params = new HttpParams();

    Object.keys(query).forEach(key => {
      let data = query[key];
      if (data)
        params = params.append(key, data);
    });
    return this._http.get<any>(`${$REST_URI}/form/submissions/${formId}`, { params });
  }

}
