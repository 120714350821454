<h2 id="title" mat-dialog-title>Crop Image</h2>
<mat-dialog-content>
    <div class="cropper-container">
        <image-cropper id="cropper" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="options.maintainAspectRatio"
            [aspectRatio]="options.aspectRatio" [resizeToHeight]="options.resizeToHeight"
            [imageQuality]="options.imageQuality" [resizeToWidth]="options.resizeToWidth" format="jpeg"
            (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()"
            (loadImageFailed)="loadImageFailed()">
        </image-cropper>
        <div class="loader-container" *ngIf="loading">
            <mat-spinner id="spinner" [strokeWidth]="5" mode="indeterminate"></mat-spinner>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end" class="cropper-action">
    <button id="crop" mat-raised-button color="primary" (click)="crop()">Crop</button>
    <button id="close" mat-button mat-dialog-close  *ngIf="!isSingleDialog" style="background-color: #ffb083;">Close</button>
    <button id="close" mat-button *ngIf="isSingleDialog" style="background-color: #ffb083;" (click)="closeDialog()">Close</button>
</mat-dialog-actions>