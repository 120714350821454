import { Directive, HostListener } from '@angular/core';
import { Location } from '@angular/common';

@Directive({
  selector: '[appBackButton]'
})
export class BackButtonDirective {
  @HostListener('click', ['$event.target'])

  onClick(){
    console.log('$event.target');
    console.log('Iam Clicked On');
    this._location.back();
  }
  constructor(private _location: Location) { }

}
