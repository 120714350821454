<div class="card task-data">
    <div>
        <mat-icon svgIcon="dialog_close" class="close-icon" (click)="goBack()"></mat-icon>
    </div>
    <div class="card-body">
        <div class="scroll-container">
            <div class="no-data-container" *ngIf="!listLoading && !taskTemplateList?.length">
                <div class="no-data-msg">No Data Found</div>
            </div>

            <div class="no-data-container" *ngIf="listLoading && !taskTemplateList?.length">
                <mat-spinner id="spinner" [strokeWidth]="4" mode="indeterminate"></mat-spinner>
            </div>

            <div class="row" style="gap: 10px;" *ngIf="!listLoading && taskTemplateList?.length">
                <div class="col-12" *ngFor="let template of taskTemplateList">
                    <div class="card" (click)="selectTemplate(template)">
                        <div class="card-body title-div" [ngClass]="{'selected-task': isTaskSelected(template._id) === true}">
                            <p>{{template.title}}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 done-btn">
                    <button mat-raised-button color="primary" (click)="selectedTemplate()" [disabled]="!selectedTaskTemplate.valid"> Next </button>
                </div>
            </div>
        </div>
    </div>
</div>