import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { camelCase } from 'lodash';
import { ProjectForm } from 'src/app/core/model/project.model';
import { User, userId } from 'src/app/core/model/user.model';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent implements OnInit, OnChanges {

  _formFields: any[];
  _formGroup: FormGroup;
  _isFormValid: boolean;
  _isFormReset: boolean;
  _allUsers: User[];
  _subProjectsCount: number | string;
  _disableAutoIncrementType1: boolean;
  _subMode: string;
  @Output() disableAutoIncrementType = new EventEmitter<any>();
  @Input('formFields') set _control(v: any) {
    if (v) this._formFields = v;
  }
  @Input('subMode') set _sub(v: any) {
    this._subMode = v;
  }
  @Input('formGroup') set _group(v: FormGroup) {
    // console.log(v);
    this._formGroup = v;
  }
  @Input('allUsers') set _users(v: User[]) {
    this._allUsers = v;
  }
  @Input('subProjectsCount') set _setSubProjectsCount(v: number | string) {
    this._subProjectsCount = v;
  }
  @Input('disableAutoIncrementType1') set _value(v: boolean) {
    this._disableAutoIncrementType1 = v;
  }
  @Input('isFormInvalid') set _formInvalid(v: boolean) {
    this._isFormValid = v;
    if (this._isFormValid === true) {
      this._formGroup.markAllAsTouched();
      this._formGroup.updateValueAndValidity();
    }
  }

  @Input('isFormReset') set _formReset(v: boolean) {
    this._isFormReset = v;
  }

  get dynamicFormGroup(): FormGroup {
    return this._formGroup as FormGroup;
  }

  getDynamicControl(key: string): FormControl {
    return this._formGroup.get(camelCase(key)) as FormControl;
  }

  constructor() { }
  checkValidation(eventData: any) {
    this.disableAutoIncrementType.emit(eventData)
  }
  ngOnInit(): void {

  }
  ngOnChanges(changes: SimpleChanges) {

  }

}
