import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup, RequiredValidator, Validators } from '@angular/forms';
import { ApprovalTypes, ProjectFields } from 'src/app/core/model/project.model';
import { camelCase } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { CropperDialogComponent, FileCropperResponse, ImageCropperOptions } from '../../image-cropper/cropper-dialog/cropper-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { $APP_VALUE } from 'src/app/core/values/app-value';
import { DataService } from 'src/app/core/services/data/data.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackBarService } from 'src/app/core/services/snackbar/snack-bar.service';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { ProjectService } from 'src/app/core/services/project/project.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { User, userId } from 'src/app/core/model/user.model';
import { EmployeeService } from 'src/app/core/services/employee/employee.service';

@Component({
  selector: 'app-dynamic-form-control',
  templateUrl: './dynamic-form-control.component.html',
  styleUrls: ['./dynamic-form-control.component.scss']
})
export class DynamicFormControlComponent implements OnInit {

  _genderPossibilities = $APP_VALUE.genderPossibilities;
  @Input() max: any;
  today = new Date();
  _formGrp: FormGroup = new FormGroup({
    _formControl: new FormControl(''),

  });
  @Output() disableAutoIncrementType = new EventEmitter<any>();
  formControl: FormControl;
  formControlType: 'TEXT' | 'TEXTAREA' | 'GENDER' | 'NUMBER' | 'RADIO' | 'CHECK_BOX' | 'AGE' | 'DATE' | 'FILE' | 'LIST' | 'MULTI_SELECT_LIST' | 'IMAGE' | 'MULTIPLE_IMAGE' | 'NOTIFICATION' | 'USERS_LIST' | 'AUTO_INCREMENT_TYPE1';
  controlName: string;
  isRequired: boolean;
  currentFormField: ProjectFields;
  possibilities: string[] = [];

  subscriptions: Subscription = new Subscription();
  uploading: boolean = false;
  imgURL: any;
  galleryImages: any[];
  noImageChange: boolean = true;
  fileName: any;
  public userRoleArr: ApprovalTypes[];
  scheduledList: any = [
    { _id: 1, name: "weekly" },
    { _id: 2, name: "monthly" },
    { _id: 3, name: "yearly" },
  ];

  showImageError: boolean = false;
  imageIsRequired: boolean = false;

  dialogType: string;

  openDialog: number = 0;

  imageUploadEvent: any;
  allUsers: User[];
  filteredUser: Observable<any>;
  isReadOnlyAssignee: boolean = false;
  selectedUser: any;
  disableAutoIncrementType1: boolean;
  subProjectsCount: number | string;
  formMode: string;
  _controlDetails: ProjectFields;
  get userCtrl(): FormControl {
    return this._formGrp.get('_formControl') as FormControl;
  }
  @Input('disableAutoIncrementType1') set _value(v: boolean) {
    this.disableAutoIncrementType1 = v;
  }
  @Input('formField') set _control(v: FormControl) {
    this.formControl = v as FormControl;
    this._formGrp.get('_formControl')?.patchValue(this.formControl.value);
    this._formGrp.get('_formControl')?.updateValueAndValidity();
  }
  @Input('subProjectsCount') set _subProjectCount(v: number | string) {
    this.subProjectsCount = v
  }
  @Input('allUsers') set _users(v: User[]) {
    this.allUsers = v;
  }
  @Input('formMode') set _setFormMode(v: string) {
    this.formMode = v;
  }


  @Input('controlDetails') set _type(v: ProjectFields) {
    this._controlDetails = v;
    if (v.type == 'IMAGE') {
      this.imgURL = this.formControl.value;
      if (v.isRequired === true) {
        this.imageIsRequired = true;
      }
    }
    else if (v.type == 'AUTO_INCREMENT_TYPE1') {
      if (this.disableAutoIncrementType1) {

        this._formGrp.controls['_formControl']?.disable();

      }
    }
    else if (v.type == 'MULTIPLE_IMAGE') {
      this.galleryImages = this.formControl.value;
      if (v.isRequired === true) {
        this.imageIsRequired = true;
      }
    }
    else if (v.type == 'NOTIFICATION') {
      this._formGrp.addControl('_notificationFormControl', this.setNotificationFormControls());
      console.log(this._formGrp);
      this._formGrp.get('_notificationFormControl')?.patchValue(this.formControl.value);
      this._formGrp.get('_notificationFormControl')?.updateValueAndValidity();
    }
    /* else if (v.type == 'USERS_LIST') {
      this._formGrp.addControl('_userFormControl', this.setNotificationFormControls());
      console.log(this._formGrp);
      this._formGrp.get('_userFormControl')?.patchValue(this.formControl.value);
      this._formGrp.get('_userFormControl')?.updateValueAndValidity();
    } */
    this.currentFormField = v;
    this.formControlType = v.type;
    this.isRequired = v.isRequired;
    this.controlName = camelCase(v.name);
    this.possibilities = v.possibilities ? v.possibilities : [];
    if (v.isRequired) {
      this._formGrp.get('_formControl')?.setValidators(Validators.required);
      this._formGrp.get('_formControl')?.updateValueAndValidity();
    }
    // this._formGrp.addControl(camelCase(v.name), new FormControl(null, v.isRequired? [Validators.required] : []));
  }

  @Input('isFormInvalid') set _formInvalid(v: boolean) {
    if (v === true) {
      this._formGrp.get('_formControl')?.markAllAsTouched();
      this._formGrp.markAllAsTouched();
      this._formGrp.updateValueAndValidity();
    }
  }

  @Input('isFormReset') set _formReset(v: boolean) {
    if (v === true) {
      this._formGrp.reset();
      this._formGrp.updateValueAndValidity();
    }
  }

  @ViewChild('userAutoCompleteInput', { read: MatAutocompleteTrigger, static: false })
  userAutoCompleteInput: MatAutocompleteTrigger;
  @ViewChild('userInput') userInput: ElementRef<HTMLInputElement>;
  constructor(
    private matDialog: MatDialog,
    private dataService: DataService,
    private changeDetectorRef: ChangeDetectorRef,
    private snackBarService: SnackBarService,
    private loadingService: LoaderService,
    private _projectService: ProjectService,
    private _loaderService: LoaderService,
    private _snackBar: SnackBarService,
    private employeeService: EmployeeService,
  ) { }

  openCropper(event: any, multiple: boolean = false) {
    console.log(event);
    this.openDialog = this.openDialog + 1;
    this.uploading = true;
    const options: ImageCropperOptions = {
      aspectRatio: 1 / 1,
      imageQuality: 70,
      resizeToHeight: 400,
      resizeToWidth: 400,
      maintainAspectRatio: true
    }

    this.matDialog.open(CropperDialogComponent, {
      width: "360px",
      data: {
        event,
        options: options,
      }
    }).afterClosed().subscribe((data: FileCropperResponse) => {
      console.log(data);
      this.openDialog = 0;
      this.imageUploadEvent = null;
      if (data) {
        var reader = new FileReader();
        reader.readAsDataURL(data.file);
        reader.onload = (_event) => {
          this.imgURL = reader.result;
        }
        console.log("close dialog data", data);
        //2097152
        if (data.file.size <= 2097152) {


          this.dataService.getPrivateUrl(data.file, 'organisation/project', 'images')
            .subscribe((res: any) => {
              console.log(res);
              $APP_VALUE.blackListUrls.push(res.data);
              $APP_VALUE.canIgnoreListUrls.push(res.data);
              console.log($APP_VALUE.canIgnoreListUrls);

              data.file.arrayBuffer().then((arrayBuffer) => {
                let blob = new Blob([new Uint8Array(arrayBuffer)], { type: data.file.type });
                console.log(blob);

                this.dataService.uploadImageFile(blob, res.data).subscribe((response: any) => {
                  event.target.value = ''
                  this.uploading = false;
                  this.showImageError = false;
                  if (multiple) {
                    let arrayValues = this.formControl.value;
                    this.galleryImages.push(reader.result);
                    console.log('this.galleryImages:', this.galleryImages)
                    /*  arrayValues.push(res.data);
                     console.log('arrayValues:',arrayValues)
                     this.galleryImages.push(reader.result);
                     console.log('arrayValues:',arrayValues) */
                    this.formControl.patchValue(arrayValues);
                    console.log('galleryImages arrayValues:', arrayValues)

                  }
                  else
                    this.formControl.patchValue(res.data);
                  this.formControl.updateValueAndValidity();
                })
              });
              this.changeDetectorRef.detectChanges();
            }, (err: HttpErrorResponse) => {
              event.target.value = ''
              console.error(err);
              this.snackBarService.error(err.error);
            });
        } else {
          this.uploading = false;
          if (this.imageIsRequired === true && !this.imgURL) {
            this.showImageError = true;
          }
          this.snackBarService.warn("Maximum image size 2Mb");
        }
      } else {
        this.uploading = false;
        event.target.value = ''
        if (this.imageIsRequired === true && !this.imgURL) {
          this.showImageError = true;
        }
      }
    });
  }
  deleteSubProjectImage(url: string) {
    let arrayValues = this.formControl.value;
    const arrayValuesIndex = arrayValues.indexOf(url);
    if (arrayValuesIndex > -1) {
      arrayValues.splice(arrayValuesIndex, 1);
    }
    const galleryIndex = this.galleryImages.indexOf(url);
    if (galleryIndex > -1) {
      this.galleryImages.splice(galleryIndex, 1);
    }
    this.formControl.patchValue(arrayValues);
  }
  uploadFile(event: any) {
    console.log("event", event);
    let dataFile: File = event.target.files[0];
    if (dataFile) {
      if (dataFile.size <= 2097152) {
        this.loadingService.activate();
        this.dataService.getPrivateUrl(dataFile, 'organisation/project', 'files')
          .subscribe((res: any) => {
            console.log(res);
            $APP_VALUE.blackListUrls.push(res.data);
            $APP_VALUE.canIgnoreListUrls.push(res.data);
            console.log($APP_VALUE.canIgnoreListUrls);

            dataFile.arrayBuffer().then((arrayBuffer) => {
              let blob = new Blob([new Uint8Array(arrayBuffer)], { type: dataFile.type });
              console.log(blob);

              this.dataService.uploadImageFile(blob, res.data).subscribe((response) => {
                console.log(response);
                this.fileName = dataFile.name;
                this.loadingService.deactivate();
                this.formControl.patchValue(res.data);
                this.formControl.updateValueAndValidity();
              }, err => {
                this.snackBarService.error(err.error);
                this.loadingService.deactivate();
              })
            });
            this.changeDetectorRef.detectChanges();
          }, (err: HttpErrorResponse) => {
            console.error(err);
            this.snackBarService.error(err.error);
            this.loadingService.deactivate();
          });
      } else {
        this._snackBar.warn("Maximum file size 2Mb");
      }
    }
  }

  private setNotificationFormControls() {
    const form = new FormGroup({
      title: new FormControl(null, [Validators.required]),
      description: new FormControl(null, [Validators.required]),
      notificationTo: new FormControl(null, [Validators.required]),
      scheduledOn: new FormControl(null, [Validators.required]),
      date: new FormControl(null, [Validators.required])
    });
    return form;
  }
  private setUserListFormControls() {
    const form = new FormGroup({
      sponsor: new FormControl(null)
    });
    return form;
  }

  public matOptionObjectComparison(option: any, value: any) {
    return option._id === value._id;
  }

  ngOnInit(): void {
    //console.log('this.formControl.value:',this.formControl.value)
    this.subscriptions.add(
      this._formGrp.get('_formControl')?.valueChanges
        .subscribe(value => {
          // if (value) {

          this.formControl.patchValue(value);
          this.formControl.updateValueAndValidity();
          // console.log('value:',value,this.disableAutoIncrementType1)
        })

    )
    this.filteredUser = this.userCtrl?.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.name)),
      map(name => (name ? this._userFilter(name) : this.allUsers?.slice())),
    );;

    this.subscriptions.add(
      this._formGrp.get('_notificationFormControl')?.valueChanges.subscribe(value => {
        // if (value) {
        this.formControl.patchValue(value);
        this.formControl.updateValueAndValidity();
        // }
      })
    )

    /*  this.subscriptions.add(this._projectService.getRolesAndUsers().subscribe((val: ApprovalTypes[]) => {
       this._loaderService.deactivate();
       this.userRoleArr = val;
 
     }, err => {
       this._loaderService.deactivate();
       this._snackBar.error('Something went wrong, Please refresh the page.')
     })); */

  }
  private _userFilter(value: any): User[] {
    console.log(value)
    const filterValue = value.toLowerCase();
    let returnValue;
    let isId = this.allUsers.filter(user => user._id.toLowerCase().includes(filterValue));
    if (isId.length > 0) {
      returnValue = isId;
    } else {
      returnValue = this.allUsers.filter(user => user.userId.name.toLowerCase().includes(filterValue));
    }
    return returnValue ? returnValue : this.allUsers;
  }
  checkUser() {
    setTimeout(() => {
      let haveUser;
      let selectedItem = this._formGrp.get('_formControl')?.value;
      if (typeof selectedItem === 'object') {
        haveUser = this.allUsers.findIndex(ele => ele._id === selectedItem?._id);
      }
      else
        haveUser = this.allUsers.findIndex(ele => ele._id === this._formGrp.get('_formControl')?.value);
      if (haveUser === -1) {
        let selectedName = "";
        this.allUsers.forEach(ele => {

          if (ele._id === this.selectedUser) {
            selectedName = ele.userId.name.toString();
          }

        })
        if (this.selectedUser) {
          this.userInput.nativeElement.value = selectedName;
          this._formGrp.get('_formControl')?.setValue(this.selectedUser);
          this._formGrp.get('_formControl')?.updateValueAndValidity();
        } else {
          this.userInput.nativeElement.value = '';
        }
        this.isReadOnlyAssignee = true;
        this.userInput.nativeElement.blur();
      } else {
        this.selectedUser = this._formGrp.get('_formControl')?.value;
        this.isReadOnlyAssignee = true;
        this.userInput.nativeElement.blur();
      }
    }, 500);
  }
  displayUserFn(data: any) {
    let returnValue;
    console.log('data:', data)
    if (this.allUsers) {
      this.allUsers.forEach(ele => {
        if (typeof data === 'object') {
          if (ele?._id === data?._id) {
            returnValue = ele?.userId?.name;
          }
        }
        else {
          if (ele?._id === data) {
            returnValue = ele?.userId?.name;
          }
        }
      });
    }
    return returnValue ? returnValue : '';
  }
  onSelectionChangedUser(event: any) {
    console.log("event", this._formGrp.get('_formControl')?.value);
    // this.assigneeCtrl.disable();
    this.selectedUser = this._formGrp.get('_formControl')?.value;
    this.isReadOnlyAssignee = true;
    this.userInput.nativeElement.blur();
  }

  enableUserInput() {
    // this.assigneeCtrl.enable();
    this.isReadOnlyAssignee = false;
    this.userInput.nativeElement.select();
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this._loaderService.deactivate();
    this.subscriptions.unsubscribe();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this._controlDetails.type == 'AUTO_INCREMENT_TYPE1') {
      this._formGrp.get('_formControl')?.clearValidators();
      this._formGrp.get('_formControl')?.disable();
      if (this.formMode == 'ADD' && !this.disableAutoIncrementType1) {
        this._formGrp.get('_formControl')?.setValue(this.subProjectsCount)
      }
      this._formGrp.get('_formControl')?.updateValueAndValidity();

      this.disableAutoIncrementType.emit({ _controlDetails: this._controlDetails, disableAutoIncrementType: true });
      if (this.disableAutoIncrementType1 === true) {
        this._formGrp.get('_formControl')?.setValue(this.subProjectsCount)
        this._formGrp.get('_formControl')?.clearValidators();
        this._formGrp.get('_formControl')?.updateValueAndValidity();
        this._formGrp.get('_formControl')?.disable()
        this.disableAutoIncrementType.emit({ _controlDetails: this._controlDetails, disableAutoIncrementType: true });
      }
      /*  else{
         this._formGrp.get('_formControl')?.enable();
         this.disableAutoIncrementType.emit({_controlDetails:this._controlDetails,disableAutoIncrementType:false})
       }  */

    }
  }
}
