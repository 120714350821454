import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { $APP_VALUE } from '../../values/app-value';
import { $icons, $iconPath } from '../../values/icons.value';

@Injectable({
  providedIn: 'root',
})
export class IconRegisterService {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {}

  registerIcons() {
    $icons.forEach((icon) => {
      this.register(icon);
      $APP_VALUE.canIgnoreListUrls.push(`${$iconPath}/${icon}.svg`);
    });
  }

  register(icon: string) {
    this.matIconRegistry.addSvgIcon(
      icon,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `${$iconPath}/${icon}.svg`
      )
    );
  }
}
