import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { empty, Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { CurrentUser, UserDetail } from 'src/app/core/model/userDetail.model';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent implements OnInit {


  subscriptions: Subscription = new Subscription();
  userData: any;

  _mobileQuery: MediaQueryList;
  @Input('mobileQuery')
  set mobileQuery(query: MediaQueryList) {
    this._mobileQuery = query;
  }

  @Output('toggleDrawer')
  openSideNavEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private router: Router,
    public authService: AuthService,
    private userService: UserService,
  ) { }

  toggleDrawer() {
    if (this._mobileQuery.matches)
      this.openSideNavEvent.emit();
  }

  userLogOut() {
    this.authService.removeToken();
    let data: CurrentUser = {
      _id : '',
      avatar: '',
      name: '',
      contactNumber: '',
      contactCode: '',
      status: '',
      organizations: [],
      creationDate: '',
      lastModifiedDate: '',
      email: ''
    }
    this.userService._currentUser.next(data);
    this.router.navigate(['/signin']);
  }

  gotoUserProfile() {
    this.router.navigate([`admin/app/user/profile/${this.userData._id}`]);
  }

  ngOnInit(): void {
    this.userService._currentUser.subscribe((res) => {
      this.userData = res;
    })

  }

}
