<div *ngIf="formFields">
    <div class="text-center text-bold pt-5 pb-3 fs-4">{{formFields.name}}</div>
    <div class="card-body project-main-container dynamic">
        <app-dynamic-form [formFields]="formFields?.form?.properties" [formGroup]="formPropertiesForm"
            [isFormInvalid]="isFormInvalid" [isFormReset]="isFormReset">
        </app-dynamic-form>
    </div>

    <div class="text-center pt-3">
        <button id="submit" type="submit" class="app_raised_btn" mat-raised-button (click)="onSubmit()"
            [disabled]="submitLoading" [class.loading]="submitLoading">
            {{formFields.buttonText}}
            <mat-spinner mode="indeterminate" *ngIf="submitLoading" [strokeWidth]="4" [diameter]="24"></mat-spinner>
        </button>
    </div>
</div>