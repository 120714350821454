import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[handleImgError]'
})

export class HandleImgErrorDirective {

  @Input('src')
  
  set _src(data: string) {
    if (!data) {
      this.ele.nativeElement.src = 'assets/images/default-avatar.png';
    }
    else
      this.ele.nativeElement.src = data;
  }

  constructor(private ele: ElementRef<HTMLImageElement>) 
  {
    ele.nativeElement.onerror = function () { this.src = 'assets/images/default-avatar.png'; };
  }

}

@Directive({
  selector: '[handleAvatarError]'
})
export class HandleAvatarErrorDirective {

  @Input('src')
  set _src(data: string) {
    console.log("ele",this.ele);
    if (!data) 
    {
      this.ele.nativeElement.src = 'assets/images/launch-default-avatar.png';
    } else 
    {
      this.ele.nativeElement.src = data;
    }
  }

  constructor(
    private ele: ElementRef<HTMLImageElement>
  ) 
  {
    ele.nativeElement.onerror = function () { this.src = 'assets/images/launch-default-avatar.png'; };
  }

}