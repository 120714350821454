import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoaderService } from '../../services/loader/loader.service';
import { SnackBarService } from '../../services/snackbar/snack-bar.service';
import { TasksService } from '../../services/tasks/tasks.service';

@Component({
  selector: 'app-task-template-list',
  templateUrl: './task-template-list.component.html',
  styleUrls: ['./task-template-list.component.scss']
})
export class TaskTemplateListComponent implements OnInit {

  taskTemplateList: any[];

  listLoading: boolean = false;

  selectedTaskTemplate = new FormControl('', [Validators.required]);

  constructor(
    public dialogRef: MatDialogRef<TaskTemplateListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private taskService: TasksService,
    private loaderService: LoaderService,
    private snackBar: SnackBarService
  ) {
    this.loaderService.activate();
    this.listLoading = true;
    this.taskService.getAllTaskTemplate().subscribe(res => {
      console.log(res);
      this.taskTemplateList = res;
      this.loaderService.deactivate();
      this.listLoading = false;
    }, err => {
      this.listLoading = false;
      this.loaderService.deactivate();
      this.snackBar.error(err.error);
    })
  }

  goBack() {
    if (location.search.includes("add") || location.search.includes("view")) {
      window.history.go(-1);
    } else {
      this.dialogRef.close();
    }
  }

  isTaskSelected(id: string) {
    let returnValue;
    if (id === this.selectedTaskTemplate.value) {
      returnValue = true;
    } else {
      returnValue = false;
    }
    return returnValue;
  }

  selectTemplate(value: any) {
    this.selectedTaskTemplate.setValue(value._id);
  }

  selectedTemplate() {
    console.log("select Template");
    console.log(this.taskTemplateList);
    let index = this.taskTemplateList.findIndex(ele => ele._id === this.selectedTaskTemplate.value);
    console.log(index);
    if(index >= 0) {
      this.dialogRef.close(this.taskTemplateList[index]);
    }
  }

  ngOnInit(): void {
    this.selectedTaskTemplate.valueChanges.subscribe(res => {
      console.log("res", res);
    })
  }

}
