import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/core/services/data/data.service';

export interface ImageCropperOptions {
  aspectRatio: number;
  resizeToHeight: number;
  resizeToWidth: number;
  imageQuality: number;
  maintainAspectRatio: boolean;
}

@Component({
  selector: 'app-cropper-dialog',
  templateUrl: './cropper-dialog.component.html',
  styleUrls: ['./cropper-dialog.component.scss']
})
export class CropperDialogComponent implements OnInit {

  imageChangedEvent: any = '';
  croppedImage: any = '';
  loading: boolean = true;

  options: ImageCropperOptions;

  isSingleDialog: boolean = false;

  private subscriptions = new Subscription();

  constructor(
    private dataService: DataService,
    public dialogRef: MatDialogRef<CropperDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: { event: ImageCroppedEvent, options: ImageCropperOptions, singleDialog: boolean }
  ) {
    console.log(data);
    this.imageChangedEvent = data.event;
    this.options = data.options;
    if (data.singleDialog) {
      this.isSingleDialog = data.singleDialog;
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    // show cropper

  }

  urltoFile(url: any, filename: any, mimeType: any) {
    return (fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }

  crop() {
    const imageName = this.imageChangedEvent.target.files[0].name;
    const contentType = this.imageChangedEvent.target.files[0].type;
    const self = this;
    this.urltoFile(this.croppedImage, imageName, contentType)
      .then(function (file) {
        self.dialogRef.close({ file, base64: self.croppedImage });
      });
  }

  //Usage example:
  cropperReady() {
    // cropper ready
    this.loading = false;
  }
  loadImageFailed() {
    // show message
  }


  closeDialog() {
    if (location.search.includes("imageUpload")) {
      window.history.go(-1);
    } else {
      this.dialogRef.close();
    }
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}

export interface FileCropperResponse {
  file: File,
  base64: string
}