import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorDirective } from './error/error.directive';
import { HandleImgErrorDirective } from './handle-img-error/handle-img-error.directive';
import { BackButtonDirective } from './backBtn/back-button.directive';
import { ValidationsDirective } from './number/validations.directive';

@NgModule({
  declarations: [
    ErrorDirective,
    HandleImgErrorDirective,
    BackButtonDirective,
    ValidationsDirective,

  ],
  imports: [
    CommonModule
  ],
  exports: [
    ErrorDirective,
    HandleImgErrorDirective,
    BackButtonDirective,
    ValidationsDirective,
  ]
})
export class DirectivesModule { }
