import { $REST_URI } from "src/environments/environment";

export const $APP_VALUE = {
    // blackListUrls: [`${$REST_URI}/employee/common/password/forgot`, `${$REST_URI}/employee/common/login`],
    blackListUrls: [
        `${$REST_URI}/account-home`,
        `${$REST_URI}/account-home/signup`,
        `${$REST_URI}/signin`,
        'assets/images',
        'assets/icons/info.svg',
        `${$REST_URI}/auth`,
        // `${$REST_URI}/user/organization`,
        // `${$REST_URI}/user/auth/forgotPassword`,
        // `${$REST_URI}/user/auth/resetPassword`,
        `${$REST_URI}/user/activate`,
        `${$REST_URI}/country`,
        `${$REST_URI}/organization/checkCode`,
        `${$REST_URI}/auth/refresh`,
        `https://api.launchplatform.org:5000/sponsors/signup-form/*`,
        // `${$REST_URI}/file/image/avatar/upload`,
        // `${$REST_URI}/file/image/logo/upload`,
        // `${$REST_URI}/file/image/avatar/download`,
        `https://launch-avatar`,
        `https://launch-image`,
        'https://launch-live.s3.amazonaws.com/',
        'https://nehemiahstorage-live.s3.amazonaws.com/',
        // `${$REST_URI}/auth/refresh`,
        'assets/icons',
        'https://launch-live.s3.amazonaws.com/',
        `${$REST_URI}/storage/public/signedURL`,
    ],
    canIgnoreListUrls: [
        // `${$REST_URI}`,
        `https://launch-avatar`,
        'assets/images',
        'assets/icons',
        'assets/icons/info.svg',
        // `${$REST_URI}/file/image/avatar/upload`,
        // `${$REST_URI}/file/image/logo/upload`,
        // `${$REST_URI}/file/image/avatar/download`,
        `https://launch-image`,
        // `https://api.launchplatform.org:5000/sponsors/signup-form/*`,
    ],
    propertiesFirstFieldTypesPossibilities: [
        { code: 'TEXT', name: 'Text', disabled: false },
        { code: 'TEXTAREA', name: 'Textarea', disabled: false },
        { code: 'NUMBER', name: 'Number', disabled: false },
        { code: 'AGE', name: 'Age', disabled: false },
        { code: 'DATE', name: 'Date', disabled: false },
        { code: 'LIST', name: 'Select List', disabled: true },
        { code: 'MULTI_SELECT_LIST', name: 'Muti Select List', disabled: true },
        { code: 'FILE', name: 'File', disabled: true },
        { code: 'IMAGE', name: 'Image', disabled: true },
        { code: 'MULTIPLE_IMAGE', name: 'Multiple Images', disabled: true },
        /* { code: 'NOTIFICATION', name: 'Notification', disabled: true } */
    ],

    propertiesFieldTypesPossibilities: [
        { code: 'TEXT', name: 'Text', disabled: false },
        { code: 'TEXTAREA', name: 'Textarea', disabled: false },
        { code: 'GENDER', name: 'Gender', disabled: false },
        { code: 'NUMBER', name: 'Number', disabled: false },
        { code: 'AGE', name: 'Age', disabled: false },
        { code: 'DATE', name: 'Date', disabled: false },
        { code: 'LIST', name: 'Select List', disabled: false },
        { code: 'USERS_LIST', name: 'Users List', disabled: false },
        { code: 'MULTI_SELECT_LIST', name: 'Muti Select List', disabled: false },
        { code: 'FILE', name: 'File', disabled: false },
        { code: 'IMAGE', name: 'Image', disabled: false },
        { code: 'MULTIPLE_IMAGE', name: 'Multiple Images', disabled: false },
        { code: 'AUTO_INCREMENT_TYPE1', name: 'Auto Increment Type 1', disabled: false },
        /* { code: 'NOTIFICATION', name: 'Notification', disabled: false } */
    ],
    genderPossibilities: [
        { value: 'M', name: 'Male'},
        { value: 'F', name: 'Female'},
        
    ],

    organizationStatus: {
        new: 'NEW',
        branchInprogress: "BRANCH_INPROGRESS",
        branchComplete: "BRANCH_COMPLETED",
        roleInprogress: "ROLES_INPROGRESS",
        roleComplete: "ROLES_COMPLETED",
        userInprogress: "USER_INPROGRESS",
        userComplete: "USER_COMPLETED"
    },
    paginatorOptions: [20, 40, 100],

    taskStatus: [{label:"All", value:""}, {label:"OPEN", value:"OPEN"}, {label:"IN-PROGRESS", value:"IN-PROGRESS"},{label:"OVERDUE", value:"OVERDUE"},{label:"DONE", value:"DONE"}],
    subProjectStatus: [{label:"All", value:""}, {label:"Pending", value:"Pending"},{label:"Present", value:"Active"},{label:"Left", value:"Left"},{label:"Replaced",value:"Replaced"}],

    taskPriority: [{label:"All", value:""}, {label:"Low", value:"Low"},{label:"Medium", value:"Medium"},{label:"High", value:"High"}],

    taskCategory: [{label:"All Tasks", value:""}, {label:"Tasks given to me, not completed", value:"GIVEN_TO_ME_NOT_COMPLETED"},{label:"Tasks given to me, completed", value:"GIVEN_TO_ME_COMPLETED"},{label:"Tasks given by me, not completed", value:"GIVEN_BY_ME_NOT_COMPLETED"}, {label:"Tasks given by me, completed", value:"GIVEN_BY_ME_COMPLETED"}],

    userStatus: [{label:"All", value:""}, {label:"Invited", value:"INVITED"},{label:"Active", value:"ACTIVE"},{label:"In-Active", value:"INACTIVE"}],

    projectStatus: [{label:"All", value:""}, {label:"Active", value:"Active"},{label:"Pending", value:"Pending"},{label:"Rejected", value:"Rejected"}],

    allRolesClaims: {
        CREATE_STAFF: "CRT_STAFF",
        UPDATE_STAFF: "UPT_STAFF",
        DELETE_STAFF: "DEL_STAFF",
        GET_STAFF: "GET_STAFF",
        CREATE_ROLE: "CRT_ROLE",
        UPDATE_ROLE: "UPT_ROLE",
        DELETE_ROLE: "DEL_ROLE",
        GET_ROLE: "GET_ROLE",
        CREATE_BRANCH: "CRT_BRN",
        UPDATE_BRANCH: "UPT_BRN",
        DELETE_BRANCH: "DEL_BRN",
        GET_BRANCH: "GET_BRN",
        CREATE_PROJECT: "CRT_PRJ",
        UPDATE_PROJECT: "UPT_PRJ",
        DELETE_PROJECT: "DEL_PRJ",
        GET_PROJECT: "GET_PRJ",
        CREATE_SUBPROJECT: "CRT_SUBPRJ",
        UPDATE_SUBPROJECT: "UPT_SUBPRJ",
        DELETE_SUBPROJECT: "DEL_SUBPRJ",
        GET_SUBPROJECT: "GET_SUBPRJ",
    },

    ckEditorMaxLimit: 2000,
}