import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { DynamicFormControlComponent } from './dynamic-form-control/dynamic-form-control.component';
import { AppCommonModule } from '../../shared/app-common/app-common.module';



@NgModule({
  declarations: [
    DynamicFormComponent,
    DynamicFormControlComponent
  ],
  imports: [
    CommonModule,
    AppCommonModule
  ],
  exports: [
    DynamicFormComponent,
    DynamicFormControlComponent
  ]
})
export class DynamicFormModule { }
