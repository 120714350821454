<form [formGroup]="dynamicFormGroup">
    <ng-container *ngIf="_formFields.length">
        <div class="dynamic-control ">
            <div class="row">
                <ng-container *ngFor="let item of _formFields">
                    <div *ngIf="item.type!='MULTIPLE_IMAGE'"
                        [class]="_subMode=='LEFT' ? 'col-md-12' : 'col-lg-6 col-md-6 col-sm-12'">
                        <app-dynamic-form-control [disableAutoIncrementType1]="_disableAutoIncrementType1"
                            [formField]="getDynamicControl(item.name)" [controlDetails]="item" [allUsers]="_allUsers"
                            [isFormReset]="_isFormReset" [isFormInvalid]="_isFormValid"
                            (disableAutoIncrementType)="checkValidation($event)" [subProjectsCount]="_subProjectsCount"
                            [formMode]="_subMode">
                        </app-dynamic-form-control>
                    </div>
                    <div *ngIf="item.type=='MULTIPLE_IMAGE'" class="col-lg-12 col-md-12 col-sm-12">
                        <app-dynamic-form-control [disableAutoIncrementType1]="_disableAutoIncrementType1"
                            [formField]="getDynamicControl(item.name)" [controlDetails]="item" [allUsers]="_allUsers"
                            [isFormReset]="_isFormReset" [isFormInvalid]="_isFormValid"
                            (disableAutoIncrementType)="checkValidation($event)" [subProjectsCount]="_subProjectsCount"
                            [formMode]="_subMode">
                        </app-dynamic-form-control>
                    </div>
                </ng-container>
            </div>

        </div>
    </ng-container>
</form>