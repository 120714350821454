import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  loader: Observable<boolean>;
  _loader: BehaviorSubject<boolean>;

  constructor() {
    this._loader = new BehaviorSubject<boolean>(false);
    this.loader = this._loader.asObservable(); 
  }

  activate() {
    this._loader.next(true);
  }

  deactivate() {
    this._loader.next(false);
  }
}
