<div class="row">
  <div class="col-md-12 top-nav">
    <mat-toolbar>
      <mat-toolbar-row>
        <span *ngIf="_mobileQuery.matches">
          <div (click)="toggleDrawer()" mat-icon-button>
            <mat-icon id="menu">menu</mat-icon>
          </div>
        </span>
        <!-- <span class="name">{{currentUser?.firstName}} {{currentUser?.lastName}}</span> -->
        <span>
          <button mat-icon-button [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger"
            (click)="menuTrigger.openMenu()" [disableRipple]="true" class="user-menu-btn">
            <mat-icon *ngIf="!userData.avatar" style="display: flexbox" svgIcon="noun_profile"></mat-icon>
            <img *ngIf="userData.avatar" class="top-nav-profile-image" [src]="userData.avatar" handleImgError/>
            <span>Welcome,
              {{userData?.name}}</span>
          </button>
          <mat-menu #menu="matMenu" [overlapTrigger]="false">
            <span (mouseleave)="menuTrigger.closeMenu()">
              <button mat-menu-item (click)="gotoUserProfile()">
                <mat-icon>person_outline</mat-icon>
                <span>Profile</span>
              </button>

              <button mat-menu-item (click)="userLogOut()">
                <mat-icon svgIcon="noun_log_out"></mat-icon>
                <span>Logout</span>
              </button>

            </span>
          </mat-menu>
        </span>
      </mat-toolbar-row>
    </mat-toolbar>
  </div>
</div>