export const $iconPath: string = 'assets/icons'
export const $imagePath: string = 'assets/images'
export const $icons: string[] = [
    'role-user',
    'branch',
    'users',
    'edit',
    'add',
    'info',
    'child-branch',
    'noun-wrong',
    'noun_users',
    'noun_branch',
    'noun_project',
    'noun_role',
    'noun_project_type',
    'noun_log_out',
    'noun_dashboard',
    'noun_profile',
    'noun_upload',
    'noun_settings',
    'noun_task',
    'noun_notification',
    'noun_todo',
    'noun_cross',
    'noun_caution',
    'noun_cancel',
    'noun_tick',
    'noun_search',
    'edit_temp',
    'delete',
    'dialog_close',
    'close',
    'role_edit',
    'blue_wrong',
    'tick',
    'calendar',
    'paste_icon',
    'noun_edit',
    'template'
]