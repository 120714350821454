import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { camelCase } from 'lodash';
import { Subscription } from 'rxjs';
import { ProjectFields } from 'src/app/core/model/project.model';
import { FormsService } from 'src/app/core/services/forms/forms.service';
import { SnackBarService } from 'src/app/core/services/snackbar/snack-bar.service';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {
  submitButton: boolean = false;
  orgCode: string;
  formId: string;
  formFields: any;
  tempProjectKeys: any[];
  formPropertiesForm: FormGroup = new FormGroup({});
  projectForm: any;
  isFormInvalid: boolean = false;
  isFormReset: boolean = false;
  submitLoading: boolean = false;
  // _subscriptions: Subscription = new Subscription();

  constructor(
    private _route: ActivatedRoute,
    private _formsService: FormsService,
    private _formBuilder: FormBuilder,
    private _snackBarService: SnackBarService
  ) {
    this.orgCode = this._route.snapshot.params['orgCode'];
    this.formId = this._route.snapshot.params['formId'];
    this.getFormById();
  }

  getCurrentData(itemName: any) {
    let data = camelCase(itemName);
    return this.formFields?.properties[data];
  }

  onSubmit() {
    this.formPropertiesForm.markAllAsTouched();
    this.formPropertiesForm.updateValueAndValidity();
    if (this.formPropertiesForm.valid) {
      this.submitLoading = true;
      let formvalue = this.formPropertiesForm.getRawValue();
      let data = {
        properties: formvalue
      }
      this._formsService.createFormSubmission(this.orgCode, this.formId, data).subscribe(res => {
        console.log(res);
        this.isFormReset = true;
        this._snackBarService.success(this.formFields.successMessage);
        window.open(this.formFields.redirectUrl,"_self");
        this.submitLoading = false;
      }, err => {
        this._snackBarService.error(err.error);
        this.submitLoading = false;
      })
    } else {
      this.isFormInvalid = true;
    }
  }

  getFormById() {
    this._formsService.getFormByOrgAndFormId(this.orgCode, this.formId).subscribe(res => {
      console.log('get form by id', res)
      this.formFields = res;
      console.log(this.formFields);
      this.tempProjectKeys = [];
      this.createFormControl();
      res?.form?.properties?.forEach((element: ProjectFields) => {
        this.tempProjectKeys.push({ name: element.name, type: element.type });
      });
    }, err => {
      console.log('get error form by id', err.error)
    })
  }



  createFormControl() {
    this.formFields.form.properties.forEach((element: any) => {
      if (element.type == 'MULTIPLE_IMAGE')
        this.formPropertiesForm.addControl(camelCase(element.name), new FormControl([], element.isRequired ? [Validators.required] : []));
      else if (element.type == 'MULTI_SELECT_LIST')
        this.formPropertiesForm.addControl(camelCase(element.name), new FormControl([], element.isRequired ? [Validators.required] : []));
      else if (element.type == 'AUTO_INCREMENT_TYPE1') {
        this.formPropertiesForm.addControl(camelCase(element.name), new FormControl(null, element.isRequired ? [Validators.required] : []));
      }
      else
        this.formPropertiesForm.addControl(camelCase(element.name), new FormControl(null, element.isRequired ? [Validators.required] : []));
    });
  }


  ngOnInit(): void { }

  ngAfterViewInit(): void { }

}
