import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { switchMap } from 'rxjs/operators';
import { CurrentUser } from '../../model/userDetail.model';
import { $APP_VALUE } from '../../values/app-value';
import { AuthService } from '../auth/auth.service';
import { TokenService } from '../token/token.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class InitializerService {

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private tokenService: TokenService,
    private router: Router,
    private jwtHelperService: JwtHelperService,
  ) { }

  init() {
    var initPromise: Promise<boolean>[] = [];

    initPromise.push(new Promise((resolve, reject) => {
      setTimeout(() => { resolve(true); }, 1500);

    }));

    initPromise.push(new Promise(async (resolve, reject) => {
      if (!this.authService.isAuthTokenExpired()) {
        this.authService.getNewToken().subscribe((result: any) => {
          this.userService.getUserAccessToken().subscribe((result: any) => {
          

            let token = this.authService.tokenGetter();
            let orgAdminRole: boolean = false;
            let claims = [];
            let roles = [];
            if (token != null) {
              let decodedToken = this.jwtHelperService.decodeToken(token);
              claims = decodedToken.claims;
              roles = decodedToken.role;
              let data;
              data = decodedToken.role.find((ele: any) => ele.role.name == 'LAUNCH_ADMIN')
              if (data) {
                orgAdminRole = true;
              } else {
                orgAdminRole = false;
              }
            }
            let user: CurrentUser = result;
            user.isOrgAdmin = orgAdminRole;
            user.roleClaims = claims;
            user.roles = roles;
            this.userService._currentUser.next(user);
            let organization = [];
            organization.push(user.organizations.find((ele: any) => ele.default === true));
            this.authService.checktheRouteBasedOnStatus(organization);
            resolve(true);
          }, err => {
            this.authService.removeToken();
            this.router.navigate(['/signin']);
            resolve(true);
          });
        },err=>{
          this.authService.removeToken();
            this.router.navigate(['/signin']);
            resolve(true);
        });

      }
      else {
        resolve(true);
      }
      /* if (!this.authService.isAuthTokenExpired()) {
        await new Promise<any>((_resolve, _reject) => {
          this.userService.getUserAccessToken().subscribe((result: any) => {

            let token = this.authService.tokenGetter();
            let orgAdminRole: boolean = false;
            let claims = [];
            let roles = [];
            if (token != null) {
              let decodedToken = this.jwtHelperService.decodeToken(token);
              claims = decodedToken.claims;
              roles = decodedToken.role;
              console.log("decodedToken", decodedToken);
              let data;
              data = decodedToken.role.find((ele: any) => ele.role.name == 'LAUNCH_ADMIN')
              if (data) {
                orgAdminRole = true;
              } else {
                orgAdminRole = false;
              }
            }
            let user: CurrentUser = result;
            user.isOrgAdmin = orgAdminRole;
            user.roleClaims = claims;
            user.roles = roles;
            this.userService._currentUser.next(user);

            console.log('result', user);
            _resolve(result);
            resolve(true);
          }, err => { _reject(err); });
        });
      } else {
        console.log('into the initializer else part');

        try {
          // get the new token and set current User using refreshToken
          if (this.authService.refreshTokenGetter() !== null) {
            console.log('into the initializer refresh token part');
            await new Promise((_resolve, _reject) => {
              this.authService.getNewToken().subscribe(res => {
                _resolve(true);
              }, err => {
                _reject(err);
              });
            });

            await new Promise<any>((_resolve, _reject) => {
              this.userService.getUserAccessToken().subscribe((result: any) => {
                let token = this.authService.tokenGetter();
                let orgAdminRole: boolean = false;
                let claims = [];
                let roles = [];
                if (token != null) {
                  let decodedToken = this.jwtHelperService.decodeToken(token);
                  claims = decodedToken.claims;
                  roles = decodedToken.role;
                  let data;
                  data = decodedToken.role.find((ele: any) => ele.role.name == 'LAUNCH_ADMIN')
                  if (data) {
                    orgAdminRole = true;
                  } else {
                    orgAdminRole = false;
                  }
                }
                let user: CurrentUser = result;
                user.isOrgAdmin = orgAdminRole;
                user.roleClaims = claims;
                user.roles = roles;
                this.userService._currentUser.next(user);
                _resolve(result);
              }, err => { _reject(err); });
            });

            resolve(true);
          } else {
            // this.authService.removeToken();
            // this.router.navigate(['account-home/signin']);
            resolve(true);
          }


        } catch (error) {
          console.log('error in getting token:',error);
          this.authService.removeToken();
          this.router.navigate(['/signin']);
          resolve(true);
        }

      } */
    }));

    return Promise.all(initPromise);
  }
}

export function initProviderFactory(provider: InitializerService) {
  return () => provider.init();
}