import { JwtModuleOptions } from "@auth0/angular-jwt";

export function tokenGetter() {
    return localStorage.getItem('auth-token');
}

export function refreshTokenGetter() {
    return localStorage.getItem('refresh-token');
}

export const JWT_Module_Options: JwtModuleOptions = {
    config: {
        tokenGetter: tokenGetter,
        // whitelistedDomains: yourWhitelistedDomains
    }
};