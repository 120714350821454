import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { $REST_URI } from 'src/environments/environment';
import { Project, ProjectType } from '../../model/project.model';
@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(private http: HttpClient) { }

  getRolesAndUsers() {
    return this.http.get<any>(`${$REST_URI}/user/userAndRoles`);
  }

  getAllSubprojectsCount() {
    return this.http.get<any>(`${$REST_URI}/project/getAllSubprojectsCount`);
  }

  getAllProjectTypes() {
    return this.http.get(`${$REST_URI}/projectType/all`);
  }
  getAllSubProjectByProjectTypeId(typeId:string) {
    return this.http.get(`${$REST_URI}/projectType/all-sub-projects/${typeId}`);
  }
  getActiveProjectTypes() {
    return this.http.get(`${$REST_URI}/projectType/active/all`);
  }
  getProjectTypeID(typeId: string) {
    return this.http.get(`${$REST_URI}/projectType/${typeId}`);
  }

  createProjectType(data: ProjectType) {
    return this.http.post<any>(`${$REST_URI}/projectType`, data);
  }

  updateProjectType(data: Project, typeId: string) {
    return this.http.put<any>(`${$REST_URI}/projectType/${typeId}`, data);
  }

  createProject(data: Project, typeId: string) {
    return this.http.post<any>(`${$REST_URI}/project/${typeId}`, data);
  }
  
  updateProject(data: Project, typeId: string, projectId: string) {
    return this.http.put<any>(`${$REST_URI}/project/${typeId}/${projectId}`, data);
  }
  updateProjectDisplayColumns(data: Project, typeId: string, projectId: string) {
    return this.http.put<any>(`${$REST_URI}/project/${typeId}/${projectId}/display-columns`, data);
  }
  
  getProjectList(query: any) {
    let params = new HttpParams();

    Object.keys(query).forEach(key => {
      let data = query[key];
      if (data)
        params = params.append(key, data);
    });
    return this.http.get<any>(`${$REST_URI}/project`, { params });
  }
  getSponsorshipProjectList(query: any) {
    let params = new HttpParams();

    Object.keys(query).forEach(key => {
      let data = query[key];
      if (data)
        params = params.append(key, data);
    });
    return this.http.get<any>(`${$REST_URI}/project/sponsors`, { params });
  }
  getSponsorSubProjectByID(projectId: string, subProjectId: string) {
    return this.http.get(`${$REST_URI}/project/sponsors/subproject/${projectId}/${subProjectId}`);
  }
  getProjectByID(projectId: string) {
    return this.http.get(`${$REST_URI}/project/${projectId}`);
  }
  getSponsorProjectDetailsByID(projectId: string) {
    return this.http.get(`${$REST_URI}/project/details/${projectId}`);
  }
  
  getAllSubProjectByProjectID(projectId: string, query: any) {
    let params = new HttpParams();

    Object.keys(query).forEach(key => {
      let data = query[key];
      if (data)
        params = params.append(key, data);
    });
    return this.http.get(`${$REST_URI}/project/sub/${projectId}`, {params});
  }
  getAllDeletedSubProjectByProjectID(projectId: string) {
    let params = new HttpParams();
    return this.http.get(`${$REST_URI}/project/sub/deleted/${projectId}`);
  }
  exportAllSubProjectByProjectID(projectId: string,query: any) {
    let params = new HttpParams();
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    Object.keys(query).forEach(key => {
      let data = query[key];
      if (data)
        params = params.append(key, data);
    });
    return this.http.get(`${$REST_URI}/project/sub/export/${projectId}`, {responseType:'arraybuffer' as 'json',headers:headers,params});
  }
  importSubProjectsByProjectID(projectId: string,projectTypeId: string, data: any){
   
    return this.http.post(`${$REST_URI}/project/sub/import/${projectId}/${projectTypeId}`, {data});
  }
  importSubProjectsPreviewCheckByProjectID(projectId: string,projectTypeId: string, data: any){
   
    return this.http.post(`${$REST_URI}/project/sub/import-preview-check/${projectId}/${projectTypeId}`, {data});
  }
  getSponsorSubProjectsByProjectId(projectId: string, query: any) {
    let params = new HttpParams();

    Object.keys(query).forEach(key => {
      let data = query[key];
      if (data)
        params = params.append(key, data);
    });
    return this.http.get(`${$REST_URI}/project/sponsors/${projectId}`, {params});
  }
  
  getSubProjectByID(projectId: string, subProjectId: string,query: any) {
    let params = new HttpParams();

    Object.keys(query).forEach(key => {
      let data = query[key];
      if (data)
        params = params.append(key, data);
    });
    return this.http.get(`${$REST_URI}/project/sub/${projectId}/${subProjectId}`,{params});
  }
 

  createSubProject(data: any, typeId: string, projectId: string) {
    return this.http.post(`${$REST_URI}/project/sub/${typeId}/${projectId}`, data)
  }
  getSubProjectReplacementAutoIncrement(data: any,typeId: string, projectId: string) {
    return this.http.post(`${$REST_URI}/project/sub/${typeId}/${projectId}/getSubProjectReplacementAutoIncrement`,data);
  }
  createSubProjectReplacement(data: any, typeId: string, projectId: string) {
    return this.http.post(`${$REST_URI}/project/sub/replacement/${typeId}/${projectId}`, data)
  }
  
  updateSubProject(data: any, typeId: string, projectId: string, subProjectId: string) {
    return this.http.put(`${$REST_URI}/project/sub/${typeId}/${projectId}/${subProjectId}`, data)
  }
  exportSubProjectReport(typeId: string, projectId: string, subProjectId: string) {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.get(`${$REST_URI}/project/sub/export-report/${typeId}/${projectId}/${subProjectId}`,{responseType:'arraybuffer' as 'json',headers:headers})
  }
  deleteSubProject(data: any, typeId: string, projectId: string, subProjectId: string) {
    return this.http.put(`${$REST_URI}/project/sub/delete/${typeId}/${projectId}/${subProjectId}`, data)
  }
  subProjectsBulkAction(data: any, typeId: string, projectId: string) {
    return this.http.put(`${$REST_URI}/project/sub/bulkActions/${typeId}/${projectId}`, data)
  }
  leftSubProject(data: any, typeId: string, projectId: string, subProjectId: string) {
    return this.http.put(`${$REST_URI}/project/sub/left/${typeId}/${projectId}/${subProjectId}`, data)
  }
  updateProjectStatus(data: any, projectId: string) {
    return this.http.patch(`${$REST_URI}/project/${projectId}/updateStatus`, data)
  }
  downloadProjectAssets(projectId: string) {
    return this.http.get(`${$REST_URI}/project/${projectId}/downloadAssets`,{responseType:'arraybuffer' as 'json'})
  }

  updateSubProjectStatus(data: any, subProjectId: string) {
    return this.http.patch(`${$REST_URI}/project/sub/${subProjectId}/updateStatus`, data)
  }

  deleteProjectType(id: string) {
    return this.http.delete<any>(`${$REST_URI}/projectType/${id}`);
  }

  approveSubProject(data: any, typeId: any, projectId: any, subProjectId: any) {
    return this.http.put(`${$REST_URI}/project/sub/approve/${typeId}/${projectId}/${subProjectId}`, data)
  }

  deleteProject(id: string,data:any) {
    return this.http.put<any>(`${$REST_URI}/project/delete/${id}`,data);
  }
  rejectProject(id: string,data:any) {
    return this.http.put<any>(`${$REST_URI}/project/reject/${id}`,data);
  }
  dowloadSample(projectTypeId:string){
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.get<any>(`${$REST_URI}/projectType/download-sample/${projectTypeId}`,{responseType:'arraybuffer' as 'json',headers:headers});
  }
  

}
