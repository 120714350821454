import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackBarLoaderComponent } from '../../shared/app-common/snack-bar-loader/snack-bar-loader.component';
import { SnackBarComponent } from '../../shared/app-common/snack-bar/snack-bar.component';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(private snackBar: MatSnackBar) { }
  
  warn(message: string): MatSnackBarRef<SnackBarComponent> {
    return this.snackBar.openFromComponent(SnackBarComponent, {
      panelClass: 'warn-snackbar',
      horizontalPosition: "right",
      verticalPosition: "bottom",
      duration: 5000,
      data: {heading: 'Warning', message: message, icon: 'noun_caution', horizontalPosition: 'right'}
    });
  }
  
  info(message: string, heading?: string): MatSnackBarRef<SnackBarComponent> {
    return this.snackBar.openFromComponent(SnackBarComponent, {
      panelClass: 'info-snackbar',
      horizontalPosition: "right",
      verticalPosition: "bottom",
      duration: 5000,
      data: {heading: heading, message: message, icon: 'noun_tick', horizontalPosition: 'right'}
    });
  }
  
  success(message: string): MatSnackBarRef<SnackBarComponent> {
    return this.snackBar.openFromComponent(SnackBarComponent, {
      panelClass: 'success-snackbar',
      horizontalPosition: "center",
      verticalPosition: "bottom",
      duration: 5000,
      data: {heading: 'Success', message: message, icon: 'noun_tick', horizontalPosition: 'center'}
    });
  }
  
  error(message: string): MatSnackBarRef<SnackBarComponent> {
    return this.snackBar.openFromComponent(SnackBarComponent, {
      panelClass: 'error-snackbar',
      horizontalPosition: "left",
      verticalPosition: "bottom",
      duration: 5000,
      data: {heading: 'Error', message: message, icon: 'noun_cancel', horizontalPosition: 'left'}
    });
  }

  loader(text?: string): MatSnackBarRef<SnackBarLoaderComponent> {
    return this.snackBar.openFromComponent(SnackBarLoaderComponent, {
      panelClass: 'info-snackbar',
      horizontalPosition: "right",
      verticalPosition: "bottom",
      data: {message: text || 'Processing', horizontalPosition: 'right'}
    });
  }

  retryLoader(text?: string): MatSnackBarRef<SnackBarLoaderComponent> {
    return this.snackBar.openFromComponent(SnackBarLoaderComponent, {
      panelClass: 'info-snackbar',
      horizontalPosition: "right",
      verticalPosition: "bottom",
      data: {message: text || 'Lost Connection, Retrying', horizontalPosition: 'right'}
    });
  }

}
