import { Injectable } from '@angular/core';
import { $REST_URI } from 'src/environments/environment';

const ACCESS_TOKEN = 'auth-token';
const REFRESH_TOKEN = 'refresh-token';

@Injectable({
  providedIn: 'root'
})

export class TokenService {

  constructor() { }

  getToken() {
    return localStorage.getItem(ACCESS_TOKEN);
  }

  getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN);
  }

  saveToken(token: any): void {
    localStorage.setItem(ACCESS_TOKEN, token);
  }

  saveRefreshToken(refreshToken: string): void {
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
  }

  removeToken(): void {
    localStorage.removeItem(ACCESS_TOKEN);
  }

  removeRefreshToken(): void {
    localStorage.removeItem(REFRESH_TOKEN);
  }

}
