import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { $REST_URI } from 'src/environments/environment';
import { User } from '../../model/user.model';
import { UserResponse } from '../../model/user.model';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  constructor(private http: HttpClient) { }

  getRole() {
    return this.http.get<any>(`${$REST_URI}/role/all`);
  }

  getAllRole() {
    return this.http.get<any>(`${$REST_URI}/role/getAll`);
  }

  getBranch() {
    return this.http.get<any>(`${$REST_URI}/branch/names`);
  }
  registerUser(data: any) {
    return this.http.post<any>(`${$REST_URI}/user`, data);
  }
  updateUser(data: any, id: string) {
    return this.http.put<any>(`${$REST_URI}/user/${id}`, data);
  }

  getNewUsers() {
    return this.http.get<any>(`${$REST_URI}/user/new`);
  }

  getUserById(id: string) {
    return this.http.get<any>(`${$REST_URI}/user/${id}`);
  }

  getUserProfile() {
    return this.http.get<any>(`${$REST_URI}/user/profile`);
  }

  getAllUsers(filter?: any) {
    if (filter) {
      let params = new HttpParams();

      Object.keys(filter).forEach(key => {
        let data = filter[key];
        if (data)
          params = params.append(key, data);
      });
      return this.http.get<UserResponse>(`${$REST_URI}/user`, { params });
    } else {
      return this.http.get<UserResponse>(`${$REST_URI}/user`);
    }
  }

  sendInvitation() {
    return this.http.get<any>(`${$REST_URI}/user/sendInvitation`);
  }
  updateCurrentUser(data: User) {
    return this.http.put<any>(`${$REST_URI}/user/profile`, data);

  }
}
