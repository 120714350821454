import { Injectable } from '@angular/core';
import { $APP_URI, $REST_URI, $URI } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Organisation } from '../../model/organization.model';
import { Country } from '../../model/country.model';
import { ActivationUser, CurrentUser } from '../../model/userDetail.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import { map } from 'rxjs/operators';

import { UserDetailResponse } from '../../model/userDetail.model';
import { $APP_VALUE } from '../../values/app-value';
import { Router } from '@angular/router';
import { UserService } from '../user/user.service';

const OAUTH_CLIENT = 'express-client';
const OAUTH_SECRET = 'express-secret';

interface TokenResponse {
  accessToken: string, refreshToken: string
}

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  currentUser: CurrentUser;

  constructor(
    private http: HttpClient,
    private jwt: JwtHelperService,
    private router: Router,
    private userService: UserService,
  ) {
    this.userService.currentUser.subscribe(res => {
      this.currentUser = res;
    })
  }

  // signUpOrganisation(data: Organisation) {
  //   return this.http.post(`${$REST_URI}/user/organization`, data);
  // }

  signUpOrganisation(data: Organisation) {
    return this.http.post<UserDetailResponse>(`${$REST_URI}/organization`, data);
  }
  login(data: any) {
    console.log("login data", data);
    return this.http.post<UserDetailResponse>(`${$REST_URI}/auth/login`, data);
  }

  getCountry() {
    return this.http.get<Country[]>(`${$REST_URI}/country`);
  }

  getState(data: string) {
    let params = new HttpParams;

    params = params.append("name", data);

    return this.http.get<Country[]>(`${$REST_URI}/country`, { params });
  }

  tokenGetter() {
    if (localStorage.getItem('auth-token') === "null" || localStorage.getItem('auth-token') === null) {
      localStorage.removeItem('auth-token');
    } else if (localStorage.getItem('auth-token') === "undefined") {
      localStorage.removeItem('auth-token');
    }
    return localStorage.getItem('auth-token');
  }

  refreshTokenGetter() {
    return localStorage.getItem('refresh-token');
  }

  isAuthTokenExpired(): boolean {
    let accessToken = this.tokenGetter();
    return accessToken ? this.jwt.isTokenExpired(accessToken) : true;
  }

  // isRefreshTokenExpired(): boolean {
  //   let refreshToken = this.refreshTokenGetter();
  //   return refreshToken ? this.jwt.isTokenExpired(refreshToken) : true;
  // }

  getNewToken() {
    let refreshToken = this.refreshTokenGetter();
    // if (refreshToken) {
    //   const data = this.jwt.decodeToken(refreshToken);
    // }
    // let url = `${$REST_URI}/getAccessToken`;
    let url = `${$REST_URI}/auth/refresh`;
    // console.log(url);

    return this.http.post<TokenResponse>(url, { refreshToken })
      .pipe(map(tokens => {
        this.setNewToken(tokens.accessToken, tokens.refreshToken);
        return tokens;
      }));
  }

  setNewToken(accessToken: string, refreshToken: string) {
    console.log('setNewToken()')
    localStorage.setItem('auth-token', accessToken);
    localStorage.setItem('refresh-token', refreshToken);
  }

  removeToken() {
    localStorage.removeItem('auth-token');
    localStorage.removeItem('refresh-token');
  }

  checktheRouteBasedOnStatus(organizations: any, isLoggin?: boolean) {
    console.log("organization", organizations);
    if (this.currentUser._id) {
      if ((this.currentUser.isOrgAdmin === true || organizations.length > 1) && isLoggin) {
        this.router.navigate(['/admin/select-organisation']);
      } else {
        let organization = organizations.find((ele: any) => ele.default === true);
        let status = organization.organizationId.status;
        console.log("status", status);
        if (status === $APP_VALUE.organizationStatus.branchComplete || status === $APP_VALUE.organizationStatus.roleInprogress) {
          if (!location.pathname.includes("/home/role" && "/home/branch")) {
            this.router.navigate(['/admin/organisation/home/role']);
          }
        } else if (status === $APP_VALUE.organizationStatus.roleComplete || status === $APP_VALUE.organizationStatus.userInprogress) {
          if (!location.pathname.includes("/home/user" && "/home/role" && "/home/branch")) {
            this.router.navigate(['/admin/organisation/home/user/user-explanation']);
          }
        } else if (status === $APP_VALUE.organizationStatus.new || status === $APP_VALUE.organizationStatus.branchInprogress) {
          if (!location.pathname.includes("/home/branch")) {
            this.router.navigate(['/admin/organisation/home/branch']);
          }
        } else if (status === $APP_VALUE.organizationStatus.userComplete) {
          // if (isLoggin) {
          //   this.router.navigate(['/admin/app']);
          // } else {
          //   // console.log('regex value', new RegExp(`^${$APP_URI}\/admin\/organisation\/home`));

          if (new RegExp(`^${$APP_URI}\/admin\/organisation\/home`).test(location.href) || new RegExp(`^${$APP_URI}\/admin\/select-organisation`).test(location.href) || new RegExp(`^${$APP_URI}\/signin`).test(location.href)) {
            // console.log('inside regex');
            this.router.navigate(['/admin/app']);
          }
          // }
        } else {
          if (!status) { return }

          this.router.navigate(['/admin/organisation/home']);
        }
      }
    }


  }

  public decodeToken() {
    let decodedPayload: any;
    try {
      const token = this.tokenGetter();
      if (token) {
        const tokenParts = token.split('.');
        decodedPayload = JSON.parse(atob(tokenParts[1]));
      }
    } catch (error) {
      console.error('Error decoding token:', error);
    }
    return decodedPayload;
  }

}
