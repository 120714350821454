import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './core/services/auth/auth.service';
import { IconRegisterService } from './core/services/icon-register/icon-register.service';
import { UserService } from './core/services/user/user.service';
import { $APP_VALUE } from './core/values/app-value';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DataService } from './core/services/data/data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  title = 'launch';

  deviceInfo: any;

  oldDeviceInfo: any;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.epicFunction();
    setTimeout(() => {
      let deviceUserAgent: string = event.currentTarget.navigator.userAgent;
      if (deviceUserAgent.includes("Mobile") || deviceUserAgent.includes("mobile")) {
        console.log("mobile");
        // this.deviceInfo.deviceType = 'mobile'
        // this.dataService._deviceInfo.next(this.deviceInfo);
        if (this.deviceInfo.deviceType !== "mobile") {
          window.location.reload();

        }
      } else {
        console.log("desktop");
        // this.deviceInfo.deviceType = 'desktop'
        // this.dataService._deviceInfo.next(this.deviceInfo);
        if (this.deviceInfo.deviceType !== "desktop") {
          window.location.reload();

        }
      }
    }, 500);
  }

  constructor(
    private iconRegister: IconRegisterService,
    private userService: UserService,
    private authService: AuthService,
    private deviceService: DeviceDetectorService,
    private dataService: DataService,
  ) { }

  epicFunction() {
    this.deviceInfo = null;
    // console.log(this.deviceService.getDeviceInfo());
    this.deviceInfo = this.deviceService.getDeviceInfo();
    // const isMobile = this.deviceService.isMobile();
    // const isTablet = this.deviceService.isTablet();
    // const isDesktopDevice = this.deviceService.isDesktop();
    // console.log(this.deviceInfo);
    // console.log(isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    // console.log(isTablet);  // returns if the device us a tablet (iPad etc)
    // console.log(isDesktopDevice); // returns if the app is running on a Desktop browser.
    if(this.oldDeviceInfo) {
      if(this.oldDeviceInfo.deviceType !== this.deviceInfo.deviceType){
        this.dataService._deviceInfo.next(this.deviceInfo);
      }
    } else {
      this.oldDeviceInfo = this.deviceInfo;
      this.dataService._deviceInfo.next(this.deviceInfo);
    }
  }

  ngOnInit() {
    this.iconRegister.registerIcons();
    this.epicFunction();
    this.userService.currentUser.subscribe(res => {
      if (!res._id) {
        return
      }
      if (res._id) {
        console.log('inside the app component');
        if (location.href.includes('/admin/select-organisation')) {
          this.authService.checktheRouteBasedOnStatus(res.organizations, true);
          // } else {
          //   this.authService.checktheRouteBasedOnStatus(res.organizations);
        }
      }
      this.dataService.deviceInfo.subscribe(res => {
        this.oldDeviceInfo = res;
      })
    });
  }
}
