<form [formGroup]="_formGrp">


    <div class="dynamic-control-container">

        <ng-container id="text" *ngIf="formControlType === 'TEXT'">
            <div class="form-group">
                <label>{{currentFormField.name}} <span class="color-red">{{isRequired ? '*' :'' }}</span></label>
                <input class="form-control" type="text" matInput maxlength="50" formControlName="_formControl">
                <div class="error">
                    <mat-error id="text-error" formError label="{{currentFormField.name}}"
                        [control]="_formGrp.get('_formControl')">
                    </mat-error>
                </div>
            </div>
        </ng-container>
        <ng-container id="textarea" *ngIf="formControlType === 'TEXTAREA'">
            <div class="form-group">
                <label>{{currentFormField.name}} <span class="color-red">{{isRequired ? '*' :'' }}</span></label>
                <textarea class="form-control dynamic-textarea" matInput formControlName="_formControl"></textarea>
                <div class="error">
                    <mat-error id="text-error" formError label="{{currentFormField.name}}"
                        [control]="_formGrp.get('_formControl')">
                    </mat-error>
                </div>
            </div>
        </ng-container>
        <ng-container id="gender" *ngIf="formControlType === 'GENDER'">
            <div class="form-group">
                <label>{{currentFormField.name}} <span class="color-red">{{isRequired ? '*' :'' }}</span></label>
                <mat-select class="form-control" formControlName="_formControl">
                    <mat-option *ngFor="let type of _genderPossibilities" [value]="type.value">
                        {{type.name}}
                    </mat-option>
                </mat-select>
                
                <div class="error">
                    <mat-error id="text-error" formError label="{{currentFormField.name}}"
                        [control]="_formGrp.get('_formControl')">
                    </mat-error>
                </div>
            </div>
        </ng-container>
        <ng-container id="autoIncrement" *ngIf="formControlType === 'AUTO_INCREMENT_TYPE1'">
            <div class="form-group">
                <label>{{currentFormField.name}} <span class="color-red">{{isRequired ? '*' :'' }}</span></label>
                <input class="form-control" type="text" matInput maxlength="50" formControlName="_formControl">
                <div class="error">
                    <mat-error id="text-error" formError label="{{currentFormField.name}}"
                        [control]="_formGrp.get('_formControl')">
                    </mat-error>
                </div>
            </div>
        </ng-container>
        <ng-container id="number" *ngIf="formControlType === 'NUMBER'">
            <div class="form-group">
                <label>{{currentFormField.name}} <span class="color-red">{{isRequired ? '*' :'' }}</span></label>
                <input class="form-control" min="0" type="number" matInput maxlength="50" formControlName="_formControl">
                <div class="error">
                    <mat-error id="number-error" formError label="{{currentFormField.name}}"
                        [control]="_formGrp.get('_formControl')">
                    </mat-error>
                </div>
            </div>
        </ng-container>

        <ng-container id="date" *ngIf="formControlType === 'DATE'">
            <div class="form-group with-prefix">

                <label>{{currentFormField.name}} <span class="color-red">{{isRequired ? '*' :'' }}</span></label>
                <!--(focus)="DOBPicker.open()"-->
                <input aria-placeholder="Date of birth" [matDatepicker]="DOBPicker" class="form-control"
                    formControlName="_formControl">
                <mat-datepicker-toggle matSuffix class="prefix calendar" [for]="DOBPicker">
                    <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker touchUi #DOBPicker></mat-datepicker>
                <div class="error">
                    <mat-error id="date-error" formError label="{{currentFormField.name}}"
                        [control]="_formGrp.get('_formControl')">
                    </mat-error>
                </div>
            </div>
        </ng-container>
        <ng-container id="age" *ngIf="formControlType === 'AGE'">
            <div class="form-group with-prefix">

                <label>{{currentFormField.name}} <span class="color-red">{{isRequired ? '*' :'' }}</span></label>
                <!--(focus)="DOBPicker.open()"-->
                <input aria-placeholder="Date of birth" [max]="today" [matDatepicker]="agePicker" class="form-control"
                    formControlName="_formControl">
                <mat-datepicker-toggle matSuffix class="prefix calendar" [for]="agePicker">
                    <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker touchUi #agePicker></mat-datepicker>
                <div class="error">
                    <mat-error id="date-error" formError label="{{currentFormField.name}}"
                        [control]="_formGrp.get('_formControl')">
                    </mat-error>
                </div>
            </div>
        </ng-container>
        <ng-container id="list" *ngIf="formControlType === 'LIST'">
            <div class="form-group">
                <label>{{currentFormField.name}} <span class="color-red">{{isRequired ? '*' :'' }}</span></label>
                <mat-select class="form-control" formControlName="_formControl">
                    <mat-option *ngFor="let type of possibilities" [value]="type">
                        {{type}}
                    </mat-option>
                </mat-select>
                <div class="error">
                    <mat-error id="list-error" formError label="{{currentFormField.name}}"
                        [control]="_formGrp.get('_formControl')">
                    </mat-error>
                </div>
            </div>
        </ng-container>
        <ng-container id="list" *ngIf="formControlType === 'USERS_LIST'">
            <div class="form-group">
                <label>{{currentFormField.name}} <span class="color-red">{{isRequired ? '*' :'' }}</span></label>
                <input type="text" #userAutoCompleteInput #userInput class="form-control"
                formControlName="_formControl" [matAutocomplete]="autoUser"
                                [readonly]="isReadOnlyAssignee" (focus)="enableUserInput()" (blur)="checkUser()">
                            <!-- <mat-select class="form-control" formControlName="assignedTo">
                                <mat-option *ngFor="let user of allUsers" [value]="user.userId?._id">
                                    {{user.userId.name}}
                                </mat-option>
                            </mat-select> -->
                            <!-- <input placeholder="Type user here..." [matAutocomplete]="auto"
                                formControlName="assignedTo"> -->
                            <mat-autocomplete matAutoActiveFirstOption #autoUser="matAutocomplete"
                                (optionSelected)="onSelectionChangedUser($event)"
                                [displayWith]="displayUserFn.bind(this)">
                                <!-- <mat-option *ngFor="let user of allUsers" [value]="user?._id">
                                    
                                    {{user?.name}}
                                </mat-option> -->
                                <mat-option class="mb-2 pb-1 pt-1" *ngFor="let user of filteredUser | async" [value]="user?._id">
                            
                                    <div class="d-flex align-items-center gap-2">
                                        <img [src]='user?.userId?.avatar' handleImgError class="user-img" alt="avatar">
                                        <div class="d-flex flex-column">
                                            <span>{{user?.userId?.name}}</span>
                                            <span>({{user?.uniqueId}})</span>
                                        </div>
                                    </div>
                                   
                                   
                                </mat-option>
                            </mat-autocomplete>
                
                <div class="error">
                    <mat-error id="list-error" formError label="{{currentFormField.name}}"
                        [control]="_formGrp.get('_formControl')">
                    </mat-error>
                </div>
            </div>
        </ng-container>

        <ng-container id="multi" *ngIf="formControlType === 'MULTI_SELECT_LIST'">
            <div class="form-group">
                <label>{{currentFormField.name}} <span class="color-red">{{isRequired ? '*' :'' }}</span></label>
                <mat-select class="form-control" formControlName="_formControl" multiple>
                    <mat-option *ngFor="let type of possibilities" [value]="type">
                        {{type}}
                    </mat-option>
                </mat-select>
                <div class="error">
                    <mat-error id="multi-select-error" formError label="{{currentFormField.name}}"
                        [control]="_formGrp.get('_formControl')">
                    </mat-error>
                </div>
            </div>
        </ng-container>

        <ng-container id="image" *ngIf="formControlType === 'IMAGE'">
            <div class="col-12 p-0 image-section">
                <input hidden #imageSelector type="file" accept="image/*" (change)="openCropper($event)" />
                <div class="upload-section form-group" (click)="imageSelector.click()">
                    <label class="file-upload">{{currentFormField.name? currentFormField.name : 'Upload-Photo'}} <span class="color-red">{{isRequired ? '*' :'' }}</span></label>
                    <button mat-icon-button>
                        <mat-icon svgIcon="noun_upload"></mat-icon>
                    </button>
                </div>
                <div class="preview-section form-group">
                    <mat-spinner id="spinner" mode="indeterminate" *ngIf="uploading" [strokeWidth]="4" [diameter]="24">
                    </mat-spinner>

                    <img *ngIf="!uploading" [src]="imgURL" alt="">
                </div>
            </div>
            <div class="error error-span" *ngIf="showImageError">
                <span><b>Image</b> is required </span>
            </div>
        </ng-container>
        <ng-container id="multiple-image" *ngIf="formControlType === 'MULTIPLE_IMAGE'">
            <div class="col-12 p-0 image-section">
                <input hidden #multipleImageSelector type="file" accept="image/*" (change)="openCropper($event,true)" />
                <div class="upload-section form-group" (click)="multipleImageSelector.click()">
                    <label class="file-upload">{{currentFormField.name? currentFormField.name : 'Upload-Photo'}} <span class="color-red">{{isRequired ? '*' :'' }}</span></label>
                    <button mat-icon-button>
                        <mat-icon svgIcon="noun_upload"></mat-icon>
                    </button>
                </div>
                <!-- <div class="preview-section form-group" >
                    <mat-spinner id="spinner" mode="indeterminate" *ngIf="uploading" [strokeWidth]="4" [diameter]="24">
                    </mat-spinner>
    
                    <img *ngIf="!uploading" [src]="imgURL" alt="">
                </div> -->
            </div>
            <div class="error error-span" *ngIf="showImageError">
                <span><b>Image</b> is required </span>
            </div>
            <div class="gallery-section">
                <div class="preview-section form-group" *ngIf="uploading" >
                    <mat-spinner id="spinner" mode="indeterminate"  [strokeWidth]="4" [diameter]="24">
                    </mat-spinner>
    
                    <img *ngIf="!uploading" [src]="imgURL" alt="">
                </div>
                <div *ngFor="let image of galleryImages" class="overlay">
                    <img  [src]="image" alt="">
                    <mat-icon color="red" (click)="deleteSubProjectImage(image)">close</mat-icon>
                </div>
                
            </div>
           
        </ng-container>

        <ng-container id="file" *ngIf="formControlType === 'FILE'">
            <div class="col-12 p-0 image-section">
                <input hidden #imageSelector type="file" (change)="uploadFile($event)" accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"/>
                <div class="upload-section form-group" (click)="imageSelector.click()">
                    <label class="file-upload">
                        {{currentFormField.name? currentFormField.name : 'Upload-File'}} <span class="color-red">{{isRequired ? '*' :'' }}</span>
                    </label>
                    <button mat-icon-button>
                        <mat-icon svgIcon="noun_upload"></mat-icon>
                    </button>
                </div>
                <div *ngIf="fileName" class="file-name">{{fileName}}</div>
            </div>
        </ng-container>

        <ng-container id="text" *ngIf="formControlType === 'NOTIFICATION'" formGroupName="_notificationFormControl">
            <div class="form-group">
                <label>Title</label>
                <input class="form-control" type="text" matInput maxlength="50" formControlName="title">
                <div class="error">
                    <mat-error id="text-error" formError label="Title"
                        [control]="_formGrp.get('_notificationFormControl.title')">
                    </mat-error>
                </div>
            </div>
            <div class="form-group">
                <label>Description</label>
                <textarea id="description" class="form-control" formControlName="description"></textarea>
                <div class="error">
                    <mat-error id="text-error" formError label="Description"
                        [control]="_formGrp.get('_notificationFormControl.description')">
                    </mat-error>
                </div>
            </div>
            <div class="form-group">
                <label>Notification To</label>
                <mat-select class="form-control" formControlName="notificationTo" multiple
                    [compareWith]="matOptionObjectComparison">
                    <mat-optgroup *ngFor="let type of userRoleArr" [label]="type.type" [disabled]="type.disabled">
                        <mat-option *ngFor="let data of type.data" [value]="data">
                            {{data.name | titlecase}}
                        </mat-option>
                    </mat-optgroup>
                </mat-select>

                <div class="error">
                    <mat-error id="text-error" formError label="Notification"
                        [control]="_formGrp.get('_notificationFormControl.notificationTo')">
                    </mat-error>
                </div>
            </div>
            <div class="form-group">
                <label>Scheduled On</label>
                <mat-select class="form-control" formControlName="scheduledOn">
                    <mat-option *ngFor="let schedule of scheduledList" [value]="schedule.name">{{schedule.name}}
                    </mat-option>
                </mat-select>
                <div class="error">
                    <mat-error id="text-error" formError label="Scheduled On"
                        [control]="_formGrp.get('_notificationFormControl.scheduledOn')">
                    </mat-error>
                </div>
            </div>
            <div class="form-group with-prefix">
                <label>Date</label>
                <input [matDatepicker]="picker" class="form-control" formControlName="date">
                <mat-datepicker-toggle matSuffix class="prefix calendar" [for]="picker">
                    <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker touchUi #picker></mat-datepicker>
            </div>
        </ng-container>

    </div>
</form>