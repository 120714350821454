import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { JwtModule } from '@auth0/angular-jwt';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './core/services/auth/auth.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppCommonModule } from './core/shared/app-common/app-common.module';
import { HttpRequestResponceInterceptor } from './core/interceptor/http.interceptor';
import { JWT_Module_Options } from './core/values/jwt-helper';
import { InitializerService, initProviderFactory } from './core/services/initializer/initializer.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { TaskTemplateListComponent } from './core/components/task-template-list/task-template-list.component';
import { FormComponent } from './modules/form/form.component';
import { DynamicFormModule } from './core/components/dynamic-form/dynamic-form.module';

@NgModule({
  declarations: [
    AppComponent,
    TaskTemplateListComponent,
    FormComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    JwtModule.forRoot(JWT_Module_Options),
    BrowserAnimationsModule,
    NgbModule,
    AppCommonModule,
    DynamicFormModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
    // TreeModule
  ],
  providers: [
    AuthService,
    {provide: HTTP_INTERCEPTORS, useClass: HttpRequestResponceInterceptor, multi: true},
    InitializerService,
    {
      provide: APP_INITIALIZER,
      useFactory: initProviderFactory,
      deps: [InitializerService],
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
