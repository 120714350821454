// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false
};


// export const $IP = '192.168.99.102'; //192.168.0.104
// export const $PORT = '3000';
// export const $DOMAIN = `${$IP}:${$PORT}`; //`${$IP}:${$PORT}`;

// export const $DOMAIN = 'localhost:3000' // 'api.launch.ruahtech.com'; //`${$IP}:${$PORT}`;
export const $DOMAIN = 'api.launchplatform.org:5000' // 'api.launch.ruahtech.com'; //`${$IP}:${$PORT}`;
export const $URI = `https://${$DOMAIN}`;
export const $REST_URI = `${$URI}`;
// export const $REST_URI = `${$URI}`;
export const $REST_URI_COMMON = `${$URI}/common`;
export const $SOCKET_URI = `${$URI}`;
// export const $APP_URI = `http://localhost:4200`;
export const $APP_URI = location.origin;




/*
* For easier debugging in development mode, you can import the following file
* to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
*
* This import should be commented out in production mode because it will have a negative impact
* on performance if an error is thrown.
*/
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
